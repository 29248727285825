import Vue from 'vue'
import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { fill, merge } from 'lodash'

interface ProblemNewState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;
  filering: boolean;

  problem_list: any[];

  checkedRows: any[];

}

const $state: ProblemNewState = {
  loading: false,
  loaded: false,
  filering: false,
  fatalError: false,
  serverErrors: null,

  checkedRows: [],

  problem_list: [],

}


const initState = cloneDeep($state)


export const $actions: ActionTree<ProblemNewState, RootState> = {

  initState({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get('/admins/rest/v1/problems')
        .then((response: AxiosResponse) => {
          commit('SET_PROBLEM_LIST', response.data.problems)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },


}

const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})

export const $mutations: MutationTree<ProblemNewState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
  SET_PROBLEM_LIST(state, data) {
    state.problem_list = data
  },

}

export const $getters: GetterTree<ProblemNewState, RootState> = {}

export const STORE_KEY = '$_problems'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
